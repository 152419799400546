:root {
  /* these values get overwritten when we get a response from the branding endpoint */
  --sending_bg_primary: #ffffff;
  --sending_bg_secondary: #1d74ec;
  --sending_bg_tertiary: #edf3fc;
  --sending_text_primary: #282f38;
  --sending_text_secondary: #758395;
  --sending_alert_color: #ff4620;
  --sending_success_color: #13a61f;
}

body {
  margin: 25px 20px;
  background-color: var(--sending_bg_primary);
  color: var(--sending_text_primary);
  font-family: Wix Madefor Display;
  font-size: 14px;
}

nav {
  margin: -25px -20px 0 -20px;
  background-color: var(--sending_bg_secondary);
  color: var(--sending_bg_primary);
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button {
  height: 52px;
  width: 100%;
  border-radius: 12px;
  padding: 20px 0;
  background-color: var(--sending_bg_secondary);
  color: var(--sending_bg_primary);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Wix Madefor Display;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

button:disabled,
button[disabled] {
  background-color: var(--sending_bg_tertiary);
}

label {
  font-size: 12px;
  font-weight: 500;
  display: block;
  margin: 30px 0 8px 0;
}

input {
  height: 50px;
  width: calc(100vw - 70px);
  padding: 1px 15px;
  border: none;
  background-color: var(--sending_bg_tertiary);
  color: var(--sending_text_primary);
  border-radius: 12px;
  font-family: Wix Madefor Display;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

input:focus {
  outline: none;
}

input[type="radio"] {
  accent-color: var(--sending_bg_secondary);
  height: 15px;
  width: auto;
  margin: 1.5px 15px 0 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

h1 {
  font-size: 24px;
  font-weight: 600;
}

h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

h3 {
  margin: 0;
  padding: 15px 0;
  font-weight: 600;
}

h5 {
  font-weight: 550;
  margin: 0;
  font-size: 12px;
}

ul {
  margin: 5px 0;
  padding: 0;
}

li {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

hr {
  border: 0;
  border-top: 1px solid var(--sending_bg_tertiary);
}

.page_with_nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 25px 0 -25px 0;
  height: calc(100vh - 82px);
}

.page_without_nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 25px 0 -25px 0;
  height: calc(100vh - 25px);
}

.status {
  background-color: var(--sending_bg_tertiary);
  border-radius: 12px;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.amount {
  font-size: 16px;
  font-weight: 650;
  text-align: right;
}

.nav_icon {
  margin-left: 20px;
  vertical-align: middle;
  cursor: pointer;
}

.banno_icon {
  width: fit-content;
  height: 42px;
  border-radius: 21px;
  margin-top: 10px;
  padding: 0 15px;
}

.center_div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.center_page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 85vh;
}

.center_page_with_nav {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80%;
}

.history_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 18px;
  height: 36px;
  width: 36px;
  text-align: center;
  margin-right: 12px;
}

.transfer_action_info {
  margin-top: 15px;
  display: flex;
  align-items: center;
  line-height: 20px;
}

.transfer_detail {
  font-size: 10px;
  margin: 22px 0 6px 0;
}

.scheduled_detail {
  font-size: 10px;
  margin: 10px 0 4px 0;
}

.scheduled_button {
  height: 24px;
  width: fit-content;
  padding: 4px 12px;
  background-color: var(--sending_bg_tertiary);
  color: var(--sending_bg_secondary);
  font-size: 12px;
  font-weight: 400;
  gap: 4px;
}

.error_message {
  margin-top: 15px;
  text-align: center;
  font-size: 16px;
}

.double_icon_small_icon {
  transform: scale(1.8);
  margin: -25px 0 0 65px;
  z-index: 1;
  border-radius: 20px;
}

.alert_message {
  margin-top: 30px;
  border-radius: 12px;
  background-color: var(--sending_alert_color);
  color: var(--sending_bg_primary);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  line-height: 20px;
}

button.alert_message {
  background-color: var(--sending_bg_primary);
  color: var(--sending_text_primary);
  height: 32px;
  font-size: 12px;
  justify-content: center;
  max-width: fit-content;
  border-radius: 8px;
  padding: 0px 24px;
  margin-top: 10px;
}

.modal {
  position: fixed;
  background-color: var(--sending_bg_primary);
  border-radius: 12px;
  padding: 30px;
  width: 70vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.overlay {
  position: fixed;
  background: rgba(20, 28, 38, 0.55);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

/* vertically center the text on the dropdown, Element must use classNamePrefix (set as "dropdown") */
.dropdown__value-container,
.dropdown__indicator {
  margin-top: -5px;
}

/* Branding Color, Background, & Border colors for various things, better than passing the branding prop all over the place */
/* Color */
.color_sending_bg_secondary {
  color: var(--sending_bg_secondary);
}

.color_sending_text_secondary {
  color: var(--sending_text_secondary);
}

.color_sending_alert_color {
  color: var(--sending_alert_color);
}

.color_sending_success_color {
  color: var(--sending_success_color);
}

/* Background Color */
.bg_sending_bg_primary {
  background-color: var(--sending_bg_primary);
}

.bg_sending_bg_secondary {
  background-color: var(--sending_bg_secondary);
}

.bg_sending_bg_tertiary {
  background-color: var(--sending_bg_tertiary);
}

.bg_sending_text_secondary {
  background-color: var(--sending_text_secondary);
}

/* Border Color */
.border_sending_bg_secondary {
  border: solid;
  border-width: 1px;
  border-color: var(--sending_bg_secondary);
}

.bottom_border_sending_bg_tertiary {
  border-bottom: solid;
  border-bottom-color: var(--sending_bg_tertiary);
}

/* Branding Fill & Stroke colors for SVGs */
/* Fill */
.fill_sending_bg_primary {
  fill: var(--sending_bg_primary);
}

.fill_sending_bg_secondary {
  fill: var(--sending_bg_secondary);
}

.fill_sending_bg_tertiary {
  fill: var(--sending_bg_tertiary);
}

.fill_sending_text_primary {
  fill: var(--sending_text_primary);
}

.fill_sending_success_color {
  fill: var(--sending_success_color);
}

.fill_sending_alert_color {
  fill: var(--sending_alert_color);
}

/* Stroke */
.stroke_sending_bg_primary {
  stroke: var(--sending_bg_primary);
}

.stroke_sending_bg_secondary {
  stroke: var(--sending_bg_secondary);
}

.stroke_sending_text_primary {
  stroke: var(--sending_text_primary);
}

.stroke_sending_text_secondary {
  stroke: var(--sending_text_secondary);
}
